import React, { useState } from 'react';
import { cacheImage } from '../../services/cache-images.service';
import useConstant from 'use-constant';
import { useAsyncEffect } from '../../hooks/useAsyncEffect';

import moneyNote from '../../images/flying-monies.png';
import './money.scss';

export default () => {
  const [loaded, setLoaded] = useState(false);
  useAsyncEffect(async () => {
    await cacheImage(moneyNote);
    setLoaded(true);
  }, []);

  const lanes = useConstant(() =>
    new Array(5).fill(0).map(_ => new Array(30).fill(0))
  );

  return (
    <div className={`sky fade ${loaded ? 'in' : ''}`}>
      {lanes.map((lane, laneIdx) => (
        <div key={laneIdx} className="lane">
          {lane.map((_, billIdx) => (
            <img key={billIdx} className="bill" src={moneyNote} />
          ))}
        </div>
      ))}
    </div>
  );
};
