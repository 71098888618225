import { useRef, useState, useEffect } from 'react';

export function useScrollCallback(callback: () => void) {
  const availableRef = useRef(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const onTouchStart = () => setIsMobile(true);

    const onScroll = () => {
      if (!availableRef.current) return;

      window.requestAnimationFrame(() => {
        callback();
        availableRef.current = true;
      });
    };

    window.addEventListener('touchstart', onTouchStart);
    window.addEventListener('touchmove', onScroll);
    if (!isMobile) window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('touchstart', onTouchStart);
      window.removeEventListener('touchmove', onScroll);
      window.removeEventListener('scroll', onScroll);
    };
  }, [isMobile, callback]);
}
