import React from 'react';
import { COLORS } from '../../colors';
import './info-block.scss';

interface Props {
  title: string;
  text: string;
  color?: string;
  headingColor?: string;
  align?: 'left' | 'right';
}

export default ({
  title,
  text,
  color = COLORS.black,
  headingColor = COLORS.white50,
  align = 'left',
}: Props) => (
  <article className="info-block">
    <h3 style={{ color: headingColor, textAlign: align }}>{title}</h3>
    <p style={{ color, textAlign: align }}>{text}</p>
  </article>
);
