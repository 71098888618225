import React from 'react';
import { COLORS } from '../../colors';
import './quote.scss';

export default () => (
  <section className="hero">
    <h1 className="hero__quote">
      <span style={{ color: COLORS.black }}>Income.</span> Your most powerful
      wealth-building tool.
    </h1>
    <p className="hero__quote-addon">
      “Your most powerful wealth-building tool is your income and when you
      haven’t committed it to someone else in the form of payments you can
      invest and begin to build wealth.”
    </p>
    <div className="hero__quote-attribution">&mdash; Dave Ramsey</div>
  </section>
);
