import React from 'react';
import './index.scss';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { COLORS } from '../colors';

import Cars from '../components/home/cars';
import Hero from '../components/home/hero';
import FlyingMoney from '../components/home/flying-money';
import Icons from '../components/home/icons';
import Quote from '../components/home/quote';
import CTA from '../components/cta';

export default () => (
  <Layout>
    <SEO title="Home" />
    <div className="interstate">
      <div className="highway">
        <Cars />
      </div>
    </div>
    <div className="constrain">
      <Hero />
    </div>
    <div style={{ background: COLORS.navy, color: COLORS.white }}>
      <FlyingMoney />
      <Icons />
    </div>
    <Quote />
    <CTA extended />
  </Layout>
);
