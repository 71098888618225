import React, { useState } from 'react';
import { cacheImages } from '../../services/cache-images.service';
import useConstant from 'use-constant';
import { useAsyncEffect } from '../../hooks/useAsyncEffect';
import './cars.scss';

import car0 from '../../images/black-lg.jpg';
import car1 from '../../images/black.jpg';
import car2 from '../../images/blue.jpg';
import car3 from '../../images/red.jpg';
import car4 from '../../images/tan-2.jpg';
import car5 from '../../images/tan.jpg';
import car6 from '../../images/white-lg.jpg';
import car7 from '../../images/white.jpg';

const availableVehicles = [car0, car1, car2, car3, car4, car5, car6, car7];

function getRandomInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max + 1 - min)) + min;
}

function getRandomVehicles(vehicles: string[], count: number): string[] {
  return new Array(count)
    .fill(0)
    .map(_ => vehicles[getRandomInt(0, vehicles.length - 1)]);
}

function buildLane(
  vehicles: string[],
  repeatSize: number,
  duration: number,
  delay: number
) {
  return {
    delay: `${delay}s`,
    duration: `${duration}s`,
    vehicles: [...vehicles, ...vehicles.slice(0, repeatSize)],
  };
}

export default () => {
  const [loaded, setLoaded] = useState(false);
  useAsyncEffect(async () => {
    await cacheImages(availableVehicles);
    setLoaded(true);
  }, []);

  const lanes = useConstant(() => [
    buildLane(getRandomVehicles(availableVehicles, 30), 10, 90, 0),
    buildLane(getRandomVehicles(availableVehicles, 30), 10, 78, -4),
    buildLane(getRandomVehicles(availableVehicles, 30), 10, 74, -2),
    buildLane(getRandomVehicles(availableVehicles, 30), 10, 82, -8),
    buildLane(getRandomVehicles(availableVehicles, 30), 10, 80, -4),
  ]);

  return (
    <div className={`road fade ${loaded ? 'in' : ''}`}>
      {lanes.map((lane, laneIdx) => (
        <div
          key={laneIdx}
          className="lane"
          style={{
            animationDuration: lane.duration,
            animationDelay: lane.delay,
          }}
        >
          {lane.vehicles.map((vehicle, vehicleIdx) => (
            <img key={vehicleIdx} className="vehicle" src={vehicle} />
          ))}
        </div>
      ))}
    </div>
  );
};
