import React from 'react';
import { COLORS } from '../../colors';
import './flying-money.scss';

import Money from './money';
import InfoBlock from './info-block';

export default () => (
  <div className="home__flying-money">
    <div className="home__money">
      <Money />
      <div className="home__money-gradient" />
    </div>
    <div className="constrain">
      <div className="text-container">
        <InfoBlock
          align="right"
          color={COLORS.white}
          title="According to Money Under 30"
          text="Leasing a car is almost always the most expensive way to operate a vehicle."
        />
      </div>
    </div>
  </div>
);
