import React from 'react';
import './hero.scss';

import InfoBlock from './info-block';
import { COLORS } from '../../colors';

export default () => {
  return (
    <div className="home__hero">
      <InfoBlock
        headingColor={COLORS.black30}
        title="Did you know?"
        text="The average lease payment in America is now $487. That’s almost $6,000 per year."
      />
    </div>
  );
};
