export async function cacheImages(images: string[], timeout = 5000) {
  for (const image of images) {
    await cacheImage(image, timeout);
  }
}

export function cacheImage(image: string, timeout = 5000) {
  return new Promise((resolve, reject) => {
    const img = new Image();

    const t = setTimeout(() => reject(`cache timeout: ${image}`), timeout);
    img.addEventListener('error', () => reject(`failed to cache: ${image}`));
    img.addEventListener('load', () => {
      clearTimeout(t);
      resolve(`cached image: ${image}`);
    });

    img.src = image;
  });
}
