import React, { useRef, useState } from 'react';
import { useScrollCallback } from '../../hooks/useScrollCallback';
import { constrain } from '../../utils';
import './icons.scss';

import house from '../../images/money-options-home-repairs.svg';
import card from '../../images/money-options-pay-off-debt.svg';
import money from '../../images/money-options-save.svg';

export default () => {
  const [step, setStep] = useState(-1);
  const ref = useRef<HTMLDivElement | null>(null);

  useScrollCallback(() => {
    const { y } = ref.current!.getBoundingClientRect();

    if (y > 0) return setStep(-1);

    const STEP_LENGTH = 350;
    setStep(Math.floor(constrain(0, 3, (y * -1) / STEP_LENGTH)) + 1);
  });

  return (
    <div className="constrain">
      <div
        ref={ref}
        className="home__icons"
        style={{ height: 'calc(650px + 100vh)', paddingBottom: '50px' }}
      >
        <div className="sticky" style={{ top: '10vh' }}>
          <article>
            <p>What could you do without a car payment?</p>
          </article>
          <div className="columns">
            <div className={`column fly-up ${step > 0 ? 'animate' : ''}`}>
              <img src={house} />
              <p>Tackle Home Repairs</p>
            </div>
            <div className={`column fly-up ${step > 1 ? 'animate' : ''}`}>
              <img src={card} />
              <p>Pay Off Other Debts</p>
            </div>
            <div className={`column fly-up ${step > 2 ? 'animate' : ''}`}>
              <img src={money} />
              <p>Save For The Future</p>
            </div>
          </div>
        </div>
      </div>
      <div className="home__icons--mobile">
        <article>
          <p>What could you do without a car payment?</p>
        </article>
        <div className="icon">
          <img src={house} />
          <p>Tackle Home Repairs</p>
        </div>
        <div className="icon">
          <img src={card} />
          <p>Pay Off Other Debts</p>
        </div>
        <div className="icon">
          <img src={money} />
          <p>Save For The Future</p>
        </div>
      </div>
    </div>
  );
};
